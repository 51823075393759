<template>
  <div id="app">
    <v-app id="inspire">
      <v-navigation-drawer
        v-model="drawer"
        fixed
        app
        color="#2AB3A3"
        :width="300"
      >
        <v-list height="120" class="mt-3" width="auto" rounded nav>
          <a-row type="flex" justify="center" align="middle">
            <a-col :span="24" class="centerdiv">
              <v-img
                src="../assets/wordmark-color-white.png"
                max-height=""
                max-width="200px"
              ></v-img>
            </a-col>
          </a-row>
          <v-divider style="color: #50c3b6" class="mb-5"></v-divider>

          <div v-for="(item, index) in items" :key="index">
            <v-list-item
              v-if="!item.childrens"
              @click="checkStart(index)"
              :to="item.to"
              router
              exact
              link
              style="color: #ffffff"
            >
              <v-list-item-content>
                <v-list-item-title
                  style="
                    font-family: 'IBMP_Light';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 26px;
                    color: #ffffff;
                    padding: 0 8px 0 16px;
                  "
                  v-text="item.title"
                />
              </v-list-item-content>
            </v-list-item>

            <v-list-group v-if="item.childrens" color="#ffffff">
              <template v-slot:activator>
                <v-list-item-title
                  style="
                    font-family: 'IBMP_Light';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 26px;
                    color: #ffffff;
                    padding: 0 8px 0 16px;
                  "
                >
                  {{ item.title }}
                </v-list-item-title>
              </template>

              <v-list-group
                no-action
                sub-group
                v-for="(itemSub, index1) in item.childrens"
                v-if="itemSub.childrens"
                :key="index1"
                class="circleMn"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title
                      style="
                        font-family: 'IBMP_Light';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 26px;
                        color: #ffffff;
                      "
                    >
                      {{ itemSub.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-if="itemSub.childrens"
                  v-for="(n, index2) in itemSub.childrens"
                  :key="index2"
                  :to="n.to"
                  link
                  style="color: #ffffff"
                >
                  <v-list-item-title
                    v-text="n.title"
                    style="
                      font-family: 'IBMP_Light';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 16px;
                      line-height: 26px;
                      color: #ffffff;
                    "
                  ></v-list-item-title>
                </v-list-item>
              </v-list-group>

              <v-list-item
                v-if="!itemSub.childrens"
                v-for="(itemSub, index3) in item.childrens"
                :key="index3"
                :to="itemSub.to"
                router
                exact
                link
                style="color: #ffffff"
              >
                <v-list-item-title
                  style="
                    font-family: 'IBMP_Light';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 26px;
                    color: #ffffff;
                    padding-left: 12px;
                  "
                  >{{ itemSub.title }}</v-list-item-title
                >
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>
      </v-navigation-drawer>
      <!-- <v-navigation-drawer v-model="drawer" app>
        <v-list height="120" class="mt-3" width="auto">
          <a-row type="flex" justify="center" align="middle">
            <a-col :span="24" class="centerdiv">
              
              <v-img
                src="../assets/wordmark-color.png"
                max-height=""
                max-width="200px"
              ></v-img>
            </a-col>
           
          </a-row>
        </v-list>
        <v-divider></v-divider>
        <a-menu
          mode="inline"
          v-for="item in Navigator"
          :key="item.key"
          v-model="selectedKeys"
        >
          <a-menu-item
            key="item.key"
            v-if="!item.menuItem"
            @click="ChangePage(item)"
          >
            <span style="font-size: 14px">{{ item.headerName }}</span>
          </a-menu-item>

          <a-sub-menu key="item.key" v-if="item.menuItem">
            <template #title style="font-size: 14px">
              {{ item.headerName }}
            </template>
            <a-menu-item
              v-for="menuList in item.menuItem"
              :key="menuList.key"
              @click="ChangePage(menuList)"
            >
              <span style="font-size: 14px">{{ menuList.text }}</span>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </v-navigation-drawer> -->
      <v-app-bar app color="#FFFFFF">
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          style="color: #2a2a2a"
        ></v-app-bar-nav-icon>
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            line-height: 36px;
          "
          class="textRes1"
        >
          {{ Header }}
          <!-- {{ window.width > 420 ? Header : "" }} -->
        </span>
        <v-spacer></v-spacer>
        <!-- <v-btn color="#2A2A2A" text @click="howto()"
          ><span class="textRes1">
            {{ window.width > 460 ? "คู่มือการใช้งาน" : "" }}
          </span>
          <a-icon type="file" class="ml-2"
        /></v-btn> -->
        <v-btn color="#2A2A2A" text @click="Logout()"
          ><span class="textRes1">
            {{ window.width > 460 ? "ออกจากระบบ" : "" }}
          </span>
          <a-icon type="import" class="ml-2"
        /></v-btn>
      </v-app-bar>
      <v-main class="div_overflow">
        <!-- <v-container grid-list-xs> -->
        <div class="pa-6">
          <router-view></router-view>
        </div>
        <!-- </v-container> -->
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      selectedKeys: ["1"],
      drawer: true,
      Header: "",
      // picture: "",
      username: "",
      items: [
        {
          icon: "mdi-chart-arc",
          title: "หน้าหลัก",
          to: "/ManageBanners",
          color: "",
        },

        {
          icon: "mdi-gavel",
          title: "สมัครเรียนและหลักสูตร",
          childrens: [
            {
              title: "ประกาศสมัครเรียน",
              childrens: [
                {
                  title: "ปริญญาโท",
                  to: "/ManageRegisterMaster",
                },
                {
                  title: "ปริญญาเอก",
                  to: "/ManageRegisterPhD",
                },
              ],
            },
            {
              title: "หลักสูตรและสาขา",
              childrens: [
                {
                  title: "ปริญญาโท",
                  to: "/ManageCourse",
                },
                {
                  title: "ปริญญาเอก",
                  to: "/ManageCoursePhD",
                },
              ],
            },
            {
              title: "ทุนการศึกษา",
              childrens: [
                {
                  title: "ปริญญาโท",
                  to: "/ManageScholarshipsMaster",
                },
                {
                  title: "ปริญญาเอก",
                  to: "/ManageScholarshipsPhD",
                },
              ],
            },
          ],
        },

        {
          icon: "mdi-gavel",
          title: "นักศึกษาปัจจุบัน",
          childrens: [
            {
              title: "ตารางการศึกษา",
              childrens: [
                {
                  title: "ปริญญาโท",
                  to: "/ManageCalendar",
                },
                {
                  title: "ปริญญาเอก",
                  to: "/ManageCalendarPhD",
                },
              ],
            },
            {
              title: "ทุนการศึกษา",
              childrens: [
                {
                  title: "ปริญญาโท",
                  to: "/ManageScholarshipsMaster1",
                },
                {
                  title: "ปริญญาเอก",
                  to: "/ManageScholarshipsPhD1",
                },
              ],
            },
            {
              title: "ดาวน์โหลดเอกสาร",
              to: "/ManageDownloadFilesStudent",
            },
            {
              title: "สอบประมวลความรู้ (ป.โท)",
              to: "/ManageKnowledgeTests",
            },
            {
              title: "สอบคุณสมบัติ (ป.เอก)",
              to: "/ManageQualificationExams",
            },
            {
              title: "ผลงานนักศึกษา",
              childrens: [
                {
                  title: "Infographic",
                  to: "/ManageStudentWorks",
                },
                {
                  title: "Video",
                  to: "/ManageVideoStudent",
                },
              ],
            },
            {
              title: "ระบบบริการนักศึกษา",
              to: "/ManageServiceSystemStudent",
            },
          ],
        },

        {
          icon: "mdi-gavel",
          title: "ข่าวสาร",
          childrens: [
            {
              title: "ข่าวและกิจกรรมคณะ",
              to: "/ManageNews",
            },
            {
              title: "ประกาศทั่วไป",
              to: "/ManageNews1",
            },
            {
              title: "ประกาศผลรับสมัคร น.ศ.",
              to: "/ManageNews2",
            },
            {
              title: "สาระน่ารู้",
              to: "/ManageNews3",
            },
          ],
        },

        {
          icon: "mdi-gavel",
          title: "งานวิชาการ",
          childrens: [
            {
              title: "วิทยานิพนธ์ และ งานวิจัย",
              to: "/ManageThesisAndResearch",
            },
            {
              title: "งานประชุมวิชาการ",
              childrens: [
                {
                  title: "ประกาศประชุม ระดับชาติ",
                  to: "/ManageAcademicConferences1",
                },
                // {
                //   title: "ส่งบทความ ระดับชาติ",
                //   to: "/CreateAcademicConferences1",
                // },
                {
                  title: "ประกาศประชุม ระดับนานาชาติ",
                  to: "/ManageAcademicConferences2",
                },
                // {
                //   title: "ส่งบทความ ระดับนานาชาติ",
                //   to: "/CreateAcademicConferences2",
                // },
              ],
            },
            {
              title: "วารสารวิชาการ",
              childrens: [
                {
                  title: "วารสารวิชาการ",
                  to: "/ManageAcademicJournal",
                },
                // {
                //   title: "ส่งบทความวิชาการ \nนิเทศศาสตร์และนวัตกรรม",
                //   to: "/ManageAcademicConferences3",
                // },
                // {
                //   title: "ส่งบทความวิชาการ \nการสื่อสารและการจัดการ",
                //   to: "/ManageAcademicConferences4",
                // },
              ],
            },
            {
              title: "คลังความรู้นิเทศศาสตร์",
              to: "/ManageKnowledgeLibraries",
            },
            {
              title: "รายงานประจำปี",
              to: "/ManageAnnualReports",
            },
          ],
        },

        {
          icon: "mdi-gavel",
          title: "เกี่ยวกับคณะ",
          childrens: [
            {
              title: "ข้อมูลคณะ",
              to: "/ManageInformation",
            },
            {
              title: "บุคลากร",
              childrens: [
                {
                  title: "คณาจารย์",
                  to: "/ManageTeacherDetails",
                },
                {
                  title: "เจ้าหน้าที่",
                  to: "/ManageOfficerDetails",
                },
              ],
            },
            // {
            //   title: "ศิษย์เก่า",
            //   childrens: [
            //     {
            //       title: "ความคิดเห็นจากศิษย์เก่า",
            //       to: "/ManageStudentComment",
            //     },
            //     {
            //       title: "บทความศิษย์เก่า",
            //       to: "/ManageAlumniArticles",
            //     },
            //     {
            //       title: "ศิษย์เก่าที่ประสบความสำเร็จ",
            //       to: "/ManageSuccessfulAlumni",
            //     },
            //   ],
            // },
            {
              title: "รางวัลที่ได้รับ",
              to: "/ManageAward",
            },
            {
              title: "เครือข่ายความร่วมมือ",
              to: "/ManageMOU",
            },
            {
              title: "อัตลักษณ์ของคณะ",
              to: "/ManageIdentity",
            },
          ],
        },
        {
          icon: "mdi-chart-arc",
          title: "ติดต่อ",
          to: "/c_configs",
          color: "",
        },
        {
          icon: "mdi-chart-arc",
          title: "เปลี่ยนรหัสผ่าน",
          to: "/ManageChangePassword",
          color: "",
        },
        // {
        //   icon: "mdi-gavel",
        //   title: "จัดการข้อมูล",
        //   childrens: [
        //     {
        //       title: "File Download",
        //       to: "/ManageFileDownload",
        //     },
        //     {
        //       title: "ระดับการศึกษา",
        //       to: "/ManageLevelCourse",
        //     },
        //     {
        //       title: "คณะ",
        //       to: "/ManageFaculty",
        //     },
        //     {
        //       title: "สาขา",
        //       to: "/ManageMajor",
        //     },
        //     {
        //       title: "จัดการบัญชีผู้ใช้",
        //       to: "/ManageUser",
        //     },
        //   ],
        // },
      ],

      NavigatorAdmin: [
        {
          key: "main1",
          headerName: "หน้าหลัก",
          path: "/ManageBanners",
          name: "ManageBanners",
        },
        {
          headerName: "สมัครเรียนและหลักสูตร",
          menuItem: [
            {
              key: "menuAnnounce1",
              text: "[ ประกาศสมัครเรียน ]",
              subMenuObject: [
                {
                  key: "menuRegis2",
                  text: "- ปริญญาโท",
                  path: "/ManageRegisterMaster",
                  name: "ManageRegisterMaster",
                  // icon: "youtube",
                },
                {
                  key: "menuRegis3",
                  text: "- ปริญญาเอก",
                  path: "/ManageRegisterPhD",
                  name: "ManageRegisterPhD",
                  // icon: "youtube",
                },
              ],
              // path: "/ManageRegisterMaster",
              // name: "ManageRegisterMaster",
              // icon: "youtube",
            },
            {
              key: "menuRegis2",
              text: "- ปริญญาโท",
              path: "/ManageRegisterMaster",
              name: "ManageRegisterMaster",
              // icon: "youtube",
            },
            {
              key: "menuRegis3",
              text: "- ปริญญาเอก",
              path: "/ManageRegisterPhD",
              name: "ManageRegisterPhD",
              // icon: "youtube",
            },
            {
              key: "menuRegis4",
              text: "[ หลักสูตรและสาขา ]",
              // path: "/ManageCourse",
              // name: "ManageCourse",
              icon: "youtube",
            },
            {
              key: "menuRegis5",
              text: "- ปริญญาโท",
              path: "/ManageCourse",
              name: "ManageCourse",
            },
            {
              key: "menuRegis6",
              text: "- ปริญญาเอก",
              path: "/ManageCoursePhD",
              name: "ManageCoursePhD",
            },
            {
              key: "menuScholarshipsMaster1",
              text: "[ ทุนการศึกษา ]",
              path: "/ManageScholarshipsMaster",
              name: "ManageScholarshipsMaster",
              icon: "youtube",
            },
            {
              key: "menuScholarshipsMaster2",
              text: "- ปริญญาโท",
              path: "/ManageScholarshipsMaster",
              name: "ManageScholarshipsMaster",
              // icon: "youtube",
            },
            {
              key: "menuScholarshipsPhD",
              text: "- ปริญญาเอก",
              path: "/ManageScholarshipsPhD",
              name: "ManageScholarshipsPhD",
              // icon: "youtube",
            },
          ],
        },
        {
          headerName: "นักศึกษาปัจจุบัน",
          menuItem: [
            {
              key: "menu5",
              text: "[ ตารางการศึกษา ]",
              // path: "/ManageBanners",
              name: "ManageBanners",
              icon: "file",
            },
            {
              key: "menuCalendarMaster",
              text: "- ปริญญาโท",
              path: "/ManageCalendar",
              name: "ManageCalendar",
              icon: "file",
            },
            {
              key: "menuCarlendarPhD",
              text: "- ปริญญาเอก",
              path: "/ManageCalendarPhD",
              name: "ManageCalendarPhD",
              icon: "file",
            },
            {
              key: "menu23",
              text: "[ ทุนการศึกษา ]",
              // path: "/table1",
              name: "table1",
              icon: "file",
            },
            {
              key: "menuScholarshipsMaster3",
              text: "- ปริญญาโท",
              path: "/ManageScholarshipsMaster1",
              name: "ManageScholarshipsMaster1",
              // icon: "youtube",
            },
            {
              key: "menuScholarshipsPhD1",
              text: "- ปริญญาเอก",
              path: "/ManageScholarshipsPhD1",
              name: "ManageScholarshipsPhD1",
              // icon: "youtube",
            },
            {
              key: "menu24",
              text: "ดาวน์โหลดเอกสาร",
              path: "/ManageDownloadFilesStudent",
              name: "ManageDownloadFilesStudent",
              icon: "file",
            },
            {
              key: "menu26",
              text: "สอบประมวลความรู้ (ป.โท)",
              path: "/ManageKnowledgeTests",
              name: "ManageKnowledgeTests",
              icon: "file",
            },
            {
              key: "menu25",
              text: "สอบคุณสมบัติ (ป.เอก)",
              path: "/ManageQualificationExams",
              name: "ManageQualificationExams",
              icon: "file",
            },

            // {
            //   key: "menu6",
            //   text: "CONTENT นศ",
            //   path: "/ManageBanners_3",
            //   name: "ManageBanners_3",
            //   icon: "file",
            // },
            {
              key: "menu7",
              text: "[ ผลงานนักศึกษา ]",
              // path: "/ManageBanners_3",
              // name: "ManageBanners_3",
              icon: "file",
            },
            {
              key: "menuInfographicStudent",
              text: "- Infographic",
              path: "/ManageStudentWorks",
              name: "ManageStudentWorks",
              // icon: "file",
            },
            {
              key: "menuVideoStudent",
              text: "- Video",
              path: "/ManageVideoStudent",
              name: "ManageVideoStudent",
              // icon: "file",
            },
            {
              key: "menu27",
              text: "ระบบบริการนักศึกษา",
              path: "/ManageServiceSystemStudent",
              name: "ManageServiceSystemStudent",
              icon: "file",
            },
          ],
        },
        {
          headerName: "ข่าวสาร",
          menuItem: [
            {
              key: "menu8",
              text: "ข่าวและกิจกรรมคณะ",
              path: "/ManageNews",
              name: "ManageNews",
              icon: "youtube",
            },
            {
              key: "menu9",
              text: "ประกาศทั่วไป",
              path: "/ManageNews1",
              name: "ManageNews1",
              icon: "youtube",
            },
            {
              key: "menu10",
              text: "ประกาศผลรับสมัคร น.ศ.",
              path: "/ManageNews2",
              name: "ManageNews2",
              icon: "youtube",
            },
            {
              key: "menu11",
              text: "สาระน่ารู้",
              path: "/ManageNews3",
              name: "ManageNews3",
              icon: "youtube",
            },
          ],
        },
        {
          headerName: "งานวิชาการ",
          menuItem: [
            {
              key: "menu12",
              text: "วิทยานิพนธ์ และ งานวิจัย",
              path: "/ManageThesisAndResearch",
              name: "ManageThesisAndResearch",
              icon: "youtube",
            },
            {
              headerName: "งานวิชาการ",
              key: "menuConference",
              text: "[ งานประชุมวิชาการ ]",
              // path: "/ManageAcademicConferences1",
              name: "ManageAcademicConferences1",
              icon: "youtube",
              // menuItem1: [
              //   {
              //     key: "menuConference",
              //     text: "- ประกาศประชุมวิชาการระดับชาติ",
              //     path: "/ManageAcademicConferences1",
              //     name: "ManageAcademicConferences1",
              //     // icon: "youtube",
              //   },
              // ],
            },
            {
              key: "menuConference0",
              text: "- ประกาศประชุม ระดับชาติ",
              path: "/ManageAcademicConferences1",
              name: "ManageAcademicConferences1",
              // icon: "youtube",
            },
            {
              key: "menuJournalPaper",
              text: "- ส่งบทความวิชาการ ระดับชาติ",
              path: "/CreateAcademicConferences1",
              name: "CreateAcademicConferences1",
              // icon: "youtube",
            },
            {
              key: "menuNationalConference",
              text: "- ประกาศประชุม ระดับนานาชาติ",
              path: "/ManageAcademicConferences2",
              name: "ManageAcademicConferences2",
              // icon: "youtube",
            },
            {
              key: "menuNationalJournalPaper",
              text: "- ส่งบทความ ระดับนานาชาติ",
              path: "/CreateAcademicConferences2",
              name: "CreateAcademicConferences2",
              // icon: "youtube",
            },
            {
              key: "menu29",
              text: "[ วารสารวิชาการ ]",
              // path: "/ManageAcademicJournal",
              name: "ManageAcademicJournal",
              icon: "youtube",
            },
            {
              key: "menuAcademicJournal",
              text: "- วารสารวิชาการ",
              path: "/ManageAcademicJournal",
              name: "ManageAcademicJournal",
              icon: "youtube",
            },
            {
              key: "menuZ1",
              text: "-  ส่งบทความวิชาการ \nนิเทศศาสตร์และนวัตกรรม",
              path: "/ManageAcademicConferences3",
              name: "ManageAcademicConferences3",
              icon: "youtube",
            },
            {
              key: "menuZ2",
              text: "-  ส่งบทความวิชาการ \nการสื่อสารและการจัดการ",
              path: "/ManageAcademicConferences4",
              name: "ManageAcademicConferences4",
              icon: "youtube",
            },
            {
              key: "menu30",
              text: "คลังความรู้นิเทศศาสตร์",
              path: "/ManageKnowledgeLibraries",
              name: "ManageKnowledgeLibraries",
              icon: "youtube",
            },
            {
              key: "menu31",
              text: "รายงานประจำปี",
              path: "/ManageAnnualReports",
              name: "ManageAnnualReports",
              icon: "youtube",
            },
          ],
        },
        {
          headerName: "เกี่ยวกับคณะ",
          menuItem: [
            {
              key: "menu13",
              text: "ข้อมูลคณะ",
              path: "/ManageInformation",
              name: "ManageInformation",
              icon: "file",
            },
            {
              key: "menu32",
              text: "[ บุคลากร ]",
              // path: "/ManageTeacherDetails",
              name: "ManageTeacherDetails",
              name: "table1",
              icon: "file",
            },
            {
              key: "menuTeacher",
              text: "- คณาจารย์",
              path: "/ManageTeacherDetails",
              name: "ManageTeacherDetails",
              // icon: "file",
            },
            {
              key: "menu33",
              text: "- เจ้าหน้าที่",
              path: "/ManageOfficerDetails",
              name: "ManageOfficerDetails",
              // icon: "file",
            },
            {
              key: "menu15",
              text: "[ ศิษย์เก่า ]",
              // path: "/ManageStudentComment",
              name: "ManageStudentComment",
              icon: "file",
            },
            {
              key: "menuStudentComment",
              text: "- ความคิดเห็นจากศิษย์เก่า",
              path: "/ManageStudentComment",
              name: "ManageStudentComment",
              icon: "file",
            },
            {
              key: "menuJournalOldStudent",
              text: "- บทความศิษย์เก่า",
              path: "/ManageAlumniArticles",
              name: "ManageAlumniArticles",
              // icon: "file",
            },
            {
              key: "menu35",
              text: "- ศิษย์เก่าที่ประสบความสำเร็จ",
              path: "/ManageSuccessfulAlumni",
              name: "ManageSuccessfulAlumni",
              // icon: "file",
            },
            {
              key: "menu17",
              text: "เครือข่ายความร่วมมือ",
              path: "/ManageMOU",
              name: "ManageMOU",
              icon: "file",
            },
            {
              key: "menu16",
              text: "รางวัลที่ได้รับ",
              path: "/ManageAward",
              name: "ManageAward",
              icon: "file",
            },
            {
              key: "menu19",
              text: "อัตลักษณ์ของคณะ",
              path: "/ManageIdentity",
              name: "ManageIdentity",
              icon: "file",
            },
          ],
        },
        {
          key: "menu20",
          headerName: "ติดต่อ",
          path: "/c_configs",
          name: "c_configs",
        },
        // {
        //   headerName: "ติดต่อ",
        //   menuItem: [
        //     {
        //       key: "menu20",
        //       text: "ข้อมูลการติดต่อ",
        //       path: "/c_configs",
        //       name: "c_configs",
        //       icon: "file",
        //     },
        //   ],
        // },
        ///////////////////////////////
        {
          headerName: "จัดการข้อมูล",
          menuItem: [
            // {
            //   key: "menuStudentAmount",
            //   text: "จัดการจำนวนนักศึกษา",
            //   path: "/studentAmount",
            //   name: "studentAmount",
            //   icon: "file",
            // },
            {
              key: "menuFileDownload",
              text: "File Download",
              path: "/ManageFileDownload",
              name: "ManageFileDownload",
              icon: "file",
            },
            {
              key: "menuLevel",
              text: "ระดับการศึกษา",
              path: "/ManageLevelCourse",
              name: "ManageLevelCourse",
              icon: "file",
            },
            {
              key: "menuFaculty",
              text: "คณะ",
              path: "/ManageFaculty",
              name: "ManageFaculty",
              icon: "file",
            },
            {
              key: "menuMajor",
              text: "สาขา",
              path: "/ManageMajor",
              name: "ManageMajor",
              icon: "file",
            },
            // {
            //   key: "menuMajorSub",
            //   text: "จัดการวิชา",
            //   path: "/ManageMajorSub",
            //   name: "ManageMajorSub",
            //   icon: "file",
            // },
            // {
            //   key: "menuNews1",
            //   text: "ข่าวสาร",
            //   path: "/ManageNews",
            //   name: "ManageNews",
            //   icon: "file",
            // },

            // {
            //   key: "menuComments",
            //   text: "บทความศิษย์เก่า",
            //   path: "/ManageAlumniArticles",
            //   name: "ManageAlumniArticles",
            //   icon: "youtube",
            // },
            {
              key: "menuUsers",
              text: "จัดการบัญชีผู้ใช้",
              path: "/ManageUser",
              name: "ManageUser",
              icon: "file",
            },
          ],
        },
        // {
        //   headerName: "ผู้ใช้งาน",
        //   menuItem: [
        //     {
        //       key: "ManageUser",
        //       text: "ข้อมูลผู้ใช้งาน",
        //       path: "/ManageUser",
        //       name: "ManageUser",
        //       icon: "user",
        //     },
        //   ],
        // },
      ],
      NavigatorDefaut: [
        {
          headerName: "ข้อมูลรีพอร์ท",
          menuItem: [
            {
              key: "table1",
              text: "ข้อมูลการแจ้งเบาะแส",
              path: "/table1",
              name: "table1",
              icon: "youtube",
            },
            {
              key: "table2",
              text: "รายชื่อผู้ต้องสงสัย",
              path: "/table2",
              name: "table2",
              icon: "youtube",
            },
            {
              key: "table3",
              text: "ข้อมูลตามประเภทข้อมูล",
              path: "/table3",
              name: "table3",
              icon: "youtube",
            },
            {
              key: "table4",
              text: "ข้อมูลตามประเภทยาเสพติด",
              path: "/table4",
              name: "table4",
              icon: "youtube",
            },
          ],
        },
      ],

      Navigator: [],

      routerPath: "",

      window: {
        width: 0,
        height: 0,
      },
    };
  },
  watch: {
    "window.width"(val) {
      console.log("window.width homepage", val);
    },
    $route(to, from) {
      // window.scrollTo(0, 0);
      // console.log("to", to);
      // console.log("from", from);
      let val = to.fullPath;
      if (val == "/ManageBanners") {
        this.Header = "หน้าหลัก";
      }
      if (val == "/ManageRegisterMaster") {
        this.Header = "ประกาศสมัครเรียน (ป.โท)";
      }
      if (val == "/CreateRegisterMaster") {
        this.Header = "เพิ่มประกาศสมัครเรียน (ป.โท)";
      }
      if (val == "/EditRegisterMaster") {
        this.Header = "แก้ไขประกาศสมัครเรียน (ป.โท)";
      }
      if (val == "/ManageRegisterPhD") {
        this.Header = "ประกาศสมัครเรียน (ป.เอก)";
      }
      if (val == "/CreateRegisterPhD") {
        this.Header = "เพิ่มประกาศสมัครเรียน (ป.เอก)";
      }
      if (val == "/EditRegisterPhD") {
        this.Header = "แก้ไขประกาศสมัครเรียน (ป.เอก)";
      }
      if (val == "/ManageCourse") {
        this.Header = "หลักสูตรและสาขา (ป.โท)";
      }
      if (val == "/CreateCourse") {
        this.Header = "เพิ่มหลักสูตรและสาขา (ป.โท)";
      }
      if (val == "/EditCourse") {
        this.Header = "แก้ไขหลักสูตรและสาขา (ป.โท)";
      }
      if (val == "/ManageCoursePhD") {
        this.Header = "หลักสูตรและสาขา (ป.เอก)";
      }
      if (val == "/CreateCoursePhD") {
        this.Header = "เพิ่มหลักสูตรและสาขา (ป.เอก)";
      }
      if (val == "/EditCoursePhD") {
        this.Header = "แก้ไขหลักสูตรและสาขา (ป.เอก)";
      }
      if (val == "/ManageScholarshipsMaster") {
        this.Header = "ทุนการศึกษา (ป.โท)";
      }
      if (val == "/CreateScholarshipsMaster") {
        this.Header = "เพิ่มทุนการศึกษา (ป.โท)";
      }
      if (val == "/EditScholarshipsMaster") {
        this.Header = "แก้ไขทุนการศึกษา (ป.โท)";
      }
      if (val == "/ManageScholarshipsPhD") {
        this.Header = "ทุนการศึกษา (ป.เอก)";
      }
      if (val == "/CreateScholarshipsPhD") {
        this.Header = "เพิ่มทุนการศึกษา (ป.เอก)";
      }
      if (val == "/EditScholarshipsPhD") {
        this.Header = "แก้ไขทุนการศึกษา (ป.เอก)";
      }
      if (val == "/ManageCalendar") {
        this.Header = "ตารางการศึกษา (ป.โท)";
      }
      if (val == "/ManageCalendarPhD") {
        this.Header = "ตารางการศึกษา (ป.เอก)";
      }
      if (val == "/ManageScholarshipsMaster1") {
        this.Header = "ทุนการศึกษา (ป.โท)";
      }
      if (val == "/CreateScholarshipsMaster1") {
        this.Header = "เพิ่มทุนการศึกษา (ป.โท)";
      }
      if (val == "/EditScholarshipsMaster1") {
        this.Header = "แก้ไขทุนการศึกษา (ป.โท)";
      }
      if (val == "/ManageScholarshipsPhD1") {
        this.Header = "ทุนการศึกษา (ป.เอก)";
      }
      if (val == "/CreateScholarshipsPhD1") {
        this.Header = "เพิ่มทุนการศึกษา (ป.เอก)";
      }
      if (val == "/EditScholarshipsPhD1") {
        this.Header = "แก้ไขทุนการศึกษา (ป.เอก)";
      }
      if (val == "/ManageDownloadFilesStudent") {
        this.Header = "ดาวน์โหลดเอกสาร";
      }
      if (val == "/ManageKnowledgeTests") {
        this.Header = "สอบประมวลความรู้";
      }
      if (val == "/ManageQualificationExams") {
        this.Header = "สอบคุณสมบัติ";
      }
      if (val == "/ManageStudentWorks") {
        this.Header = "ผลงานนักศึกษา";
      }
      if (val == "/ManageVideoStudent") {
        this.Header = "ผลงานนักศึกษา";
      }
      if (val == "/ManageServiceSystemStudent") {
        this.Header = "ระบบบริการนักศึกษา";
      }
      if (val == "/ManageNews") {
        this.Header = "ข่าวสาร";
      }
      if (val == "/CreateNews") {
        this.Header = "เพิ่มข่าวและกิจกรรมคณะ";
      }
      if (val == "/EditNews") {
        this.Header = "แก้ไขข่าวและกิจกรรมคณะ";
      }
      if (val == "/ManageNews1") {
        this.Header = "ประกาศทั่วไป";
      }
      if (val == "/CreateNews1") {
        this.Header = "เพิ่มประกาศทั่วไป";
      }
      if (val == "/EditNews1") {
        this.Header = "แก้ไขประกาศทั่วไป";
      }
      if (val == "/ManageNews2") {
        this.Header = "ประกาศผลรับสมัคร";
      }
      if (val == "/CreateNews2") {
        this.Header = "เพิ่มประกาศผลรับสมัคร";
      }
      if (val == "/EditNews2") {
        this.Header = "แก้ไขประกาศผลรับสมัคร";
      }
      if (val == "/ManageNews3") {
        this.Header = "สาระน่ารู้";
      }
      if (val == "/CreateNews3") {
        this.Header = "เพิ่มสาระน่ารู้";
      }
      if (val == "/EditNews3") {
        this.Header = "แก้ไขสาระน่ารู้";
      }
      if (val == "/ManageThesisAndResearch") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/ManageAcademicConferences1") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/CreateAcademicConferences1") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/ManageAcademicConferences2") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/CreateAcademicConferences2") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/ManageAcademicJournal") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/ManageAcademicConferences3") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/ManageAcademicConferences4") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/ManageKnowledgeLibraries") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/ManageAnnualReports") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/ManageInformation") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/ManageTeacherDetails") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/CreateTeacherDetails") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/EditTeacherDetails") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/ManageOfficerDetails") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/ManageStudentComment") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/ManageAlumniArticles") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/CreateAlumniArticles") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/EditAlumniArticles") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/ManageSuccessfulAlumni") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/ManageMOU") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/ManageAward") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/ManageIdentity") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/c_configs") {
        this.Header = "ติดต่อ";
      }
      if (val == "/ManageFileDownload") {
        this.Header = "File Download";
      }
      if (val == "/ManageLevelCourse") {
        this.Header = "ระดับการศึกษา";
      }
      if (val == "/ManageFaculty") {
        this.Header = "คณะ";
      }
      if (val == "/ManageMajor") {
        this.Header = "สาขา";
      }
      if (val == "/ManageUser") {
        this.Header = "บัญชีผู้ใช้";
      }

      window.scrollTo(0, 0);
    },
    // routerPath(val) {
    //   if (val) {
    //     console.log("router Path", val);
    //   }
    // },
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    // this.Navigator = this.NavigatorAdmin;
    var checkuser = JSON.parse(
      Decode.decode(localStorage.getItem("gscmAdmin"))
    );
    console.log("user", checkuser);
    this.username = checkuser.name;

    // if (checkuser.user.user_type == "99") {
    this.Navigator = this.NavigatorAdmin;
    // } else {
    //   this.Navigator = this.NavigatorDefaut;
    // }

    // console.log("router now", this.$router.currentRoute.fullPath);
    this.CheckHeader(this.$router.currentRoute.fullPath);

    window.scrollTo(0, 0);
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  mounted() {
    this.routerPath = this.$router.currentRoute.fullPath;
    window.scrollTo(0, 0);
  },

  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      window.scrollTo(0, 0);
    },
    howto() {
      window.open(
        "https://docs.google.com/document/d/e/2PACX-1vT4xBqg6FYpPY492g7qskLz0RMcK-I3pZdLdYGpkxTGthBPkISKAXoyPxxAfADKsNUPkuH_gZkEWpCt/pub",
        "_blank"
      );
    },
    openKeys(val) {
      console.log("openKeys", val);
    },
    handleClick(e) {
      console.log("click", e);
    },
    titleClick(e) {
      console.log("titleClick", e);
    },
    async ChangePage(val) {
      // // console.log('เข้า ChangePage', val)
      // // console.log(val, this.$router.currentRoute)
      if (val.name !== this.$router.currentRoute.name) {
        this.$router.push({ path: val.path }).catch(() => {});
      }

      window.scrollTo(0, 0);
    },
    gotoPage(val) {
      this.$router.push({ path: val.to }).catch(() => {});
      window.scrollTo(0, 0);
    },
    CheckHeader(val) {
      if (val == "/ManageBanners") {
        this.Header = "หน้าหลัก";
      }
      if (val == "/ManageRegisterMaster") {
        this.Header = "ประกาศสมัครเรียน (ป.โท)";
      }
      if (val == "/CreateRegisterMaster") {
        this.Header = "เพิ่มประกาศสมัครเรียน (ป.โท)";
      }
      if (val == "/EditRegisterMaster") {
        this.Header = "แก้ไขประกาศสมัครเรียน (ป.โท)";
      }
      if (val == "/ManageRegisterPhD") {
        this.Header = "ประกาศสมัครเรียน (ป.เอก)";
      }
      if (val == "/CreateRegisterPhD") {
        this.Header = "เพิ่มประกาศสมัครเรียน (ป.เอก)";
      }
      if (val == "/EditRegisterPhD") {
        this.Header = "แก้ไขประกาศสมัครเรียน (ป.เอก)";
      }
      if (val == "/ManageCourse") {
        this.Header = "หลักสูตรและสาขา (ป.โท)";
      }
      if (val == "/CreateCourse") {
        this.Header = "เพิ่มหลักสูตรและสาขา (ป.โท)";
      }
      if (val == "/EditCourse") {
        this.Header = "แก้ไขหลักสูตรและสาขา (ป.โท)";
      }
      if (val == "/ManageCoursePhD") {
        this.Header = "หลักสูตรและสาขา (ป.เอก)";
      }
      if (val == "/CreateCoursePhD") {
        this.Header = "เพิ่มหลักสูตรและสาขา (ป.เอก)";
      }
      if (val == "/EditCoursePhD") {
        this.Header = "แก้ไขหลักสูตรและสาขา (ป.เอก)";
      }
      if (val == "/ManageScholarshipsMaster") {
        this.Header = "ทุนการศึกษา (ป.โท)";
      }
      if (val == "/CreateScholarshipsMaster") {
        this.Header = "เพิ่มทุนการศึกษา (ป.โท)";
      }
      if (val == "/EditScholarshipsMaster") {
        this.Header = "แก้ไขทุนการศึกษา (ป.โท)";
      }
      if (val == "/ManageScholarshipsPhD") {
        this.Header = "ทุนการศึกษา (ป.เอก)";
      }
      if (val == "/CreateScholarshipsPhD") {
        this.Header = "เพิ่มทุนการศึกษา (ป.เอก)";
      }
      if (val == "/EditScholarshipsPhD") {
        this.Header = "แก้ไขทุนการศึกษา (ป.เอก)";
      }
      if (val == "/ManageCalendar") {
        this.Header = "ตารางการศึกษา (ป.โท)";
      }
      if (val == "/ManageCalendarPhD") {
        this.Header = "ตารางการศึกษา (ป.เอก)";
      }
      if (val == "/ManageScholarshipsMaster1") {
        this.Header = "ทุนการศึกษา (ป.โท)";
      }
      if (val == "/CreateScholarshipsMaster1") {
        this.Header = "เพิ่มทุนการศึกษา (ป.โท)";
      }
      if (val == "/EditScholarshipsMaster1") {
        this.Header = "แก้ไขทุนการศึกษา (ป.โท)";
      }
      if (val == "/ManageScholarshipsPhD1") {
        this.Header = "ทุนการศึกษา (ป.เอก)";
      }
      if (val == "/CreateScholarshipsPhD1") {
        this.Header = "เพิ่มทุนการศึกษา (ป.เอก)";
      }
      if (val == "/EditScholarshipsPhD1") {
        this.Header = "แก้ไขทุนการศึกษา (ป.เอก)";
      }
      if (val == "/ManageDownloadFilesStudent") {
        this.Header = "ดาวน์โหลดเอกสาร";
      }
      if (val == "/ManageKnowledgeTests") {
        this.Header = "สอบประมวลความรู้";
      }
      if (val == "/ManageQualificationExams") {
        this.Header = "สอบคุณสมบัติ";
      }
      if (val == "/ManageStudentWorks") {
        this.Header = "ผลงานนักศึกษา";
      }
      if (val == "/ManageVideoStudent") {
        this.Header = "ผลงานนักศึกษา";
      }
      if (val == "/ManageServiceSystemStudent") {
        this.Header = "ระบบบริการนักศึกษา";
      }
      if (val == "/ManageNews") {
        this.Header = "ข่าวสาร";
      }
      if (val == "/CreateNews") {
        this.Header = "เพิ่มข่าวและกิจกรรมคณะ";
      }
      if (val == "/EditNews") {
        this.Header = "แก้ไขข่าวและกิจกรรมคณะ";
      }
      if (val == "/ManageNews1") {
        this.Header = "ประกาศทั่วไป";
      }
      if (val == "/CreateNews1") {
        this.Header = "เพิ่มประกาศทั่วไป";
      }
      if (val == "/EditNews1") {
        this.Header = "แก้ไขประกาศทั่วไป";
      }
      if (val == "/ManageNews2") {
        this.Header = "ประกาศผลรับสมัคร";
      }
      if (val == "/CreateNews2") {
        this.Header = "เพิ่มประกาศผลรับสมัคร";
      }
      if (val == "/EditNews2") {
        this.Header = "แก้ไขประกาศผลรับสมัคร";
      }
      if (val == "/ManageNews3") {
        this.Header = "สาระน่ารู้";
      }
      if (val == "/CreateNews3") {
        this.Header = "เพิ่มสาระน่ารู้";
      }
      if (val == "/EditNews3") {
        this.Header = "แก้ไขสาระน่ารู้";
      }
      if (val == "/ManageThesisAndResearch") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/ManageAcademicConferences1") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/CreateAcademicConferences1") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/ManageAcademicConferences2") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/CreateAcademicConferences2") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/ManageAcademicJournal") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/ManageAcademicConferences3") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/ManageAcademicConferences4") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/ManageKnowledgeLibraries") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/ManageAnnualReports") {
        this.Header = "งานวิชาการ";
      }
      if (val == "/ManageInformation") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/ManageTeacherDetails") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/CreateTeacherDetails") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/EditTeacherDetails") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/ManageOfficerDetails") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/ManageStudentComment") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/ManageAlumniArticles") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/CreateAlumniArticles") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/EditAlumniArticles") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/ManageSuccessfulAlumni") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/ManageMOU") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/ManageAward") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/ManageIdentity") {
        this.Header = "ข้อมูลคณะ";
      }
      if (val == "/c_configs") {
        this.Header = "ติดต่อ";
      }
      if (val == "/ManageFileDownload") {
        this.Header = "File Download";
      }
      if (val == "/ManageLevelCourse") {
        this.Header = "ระดับการศึกษา";
      }
      if (val == "/ManageFaculty") {
        this.Header = "คณะ";
      }
      if (val == "/ManageMajor") {
        this.Header = "สาขา";
      }
      if (val == "/ManageUser") {
        this.Header = "บัญชีผู้ใช้";
      }
    },
    Logout() {
      localStorage.removeItem("gscmAdmin");
      window.location.reload();
    },
    checkStart(index) {
      if (index === 1) {
        this.$store.commit("stepper/setCases", 0);
      }
    },
  },
};
</script>
<style scoped>
.centerdiv {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.div_overflow {
  background-color: #f5f5f5;
  overflow: auto;
  width: 100%;
  height: 94vh;
}
</style>
<style>
.v-application ul,
.v-application ol {
  padding: 0px 0px !important;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: red;
  border-bottom: 2px solid red;
}

.v-list-group__items {
  background-color: #3cc3b4;
}

.circleMn .mdi-menu-down::before {
  content: "\F0766";
  color: white;
  scale: 0.5;
}

.mdi-chevron-down::before {
  content: "\F0140";
  color: white;
}

.v-application--is-ltr
  .v-list-group--sub-group
  .v-list-item__action:first-child,
.v-application--is-ltr
  .v-list-group--sub-group
  .v-list-item__avatar:first-child,
.v-application--is-ltr .v-list-group--sub-group .v-list-item__icon:first-child {
  margin-right: 0px !important;
}

.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
  padding-left: 12px !important;
}

.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 38px !important;
}

@media (max-width: 768px) {
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    min-width: 150px !important;
  }
}
</style>
