import Vue from "vue";
import VueRouter from "vue-router";
import Homepage from "@/views/Homepage.vue";
import login from "../views/Login.vue";
import forgotPassword from "@/views/forgotPassword.vue";
import forgotPasswordSuccess from "@/views/forgotPasswordSuccess.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
  },
  { path: "/forgotpassword", name: "forgot", component: forgotPassword },
  {
    path: "/forgotPasswordSuccess",
    name: "forgotSuccess",
    component: forgotPasswordSuccess,
  },
  {
    path: "/printpdf",
    name: "printpdf",
    component: () => import("@/views/printpdf.vue"),
  },
  {
    path: "/ExportVat",
    name: "ExportVat",
    component: () => import("@/views/ExportExcel/ExportVat.vue"),
  },
  {
    path: "/invc",
    name: "invc",
    component: () => import("@/views/ExportExcel/ExportInvoiceeee.vue"),
  },
  {
    path: "/Home",
    component: Homepage,
    children: [
      {
        path: "/ManageServiceSystemStudent",
        name: "ManageServiceSystemStudent",
        component: () => import("@/views/ManageServiceSystemStudent"),
      },
      {
        path: "/ManageVideoStudent",
        name: "ManageVideoStudent",
        component: () => import("@/views/ManageVideoStudent"),
      },
      {
        path: "/ManageDownloadFilesStudent",
        name: "ManageDownloadFilesStudent",
        component: () => import("@/views/ManageDownloadFilesStudent"),
      },
      {
        path: "/ManageCalendarPhD",
        name: "ManageCalendarPhD",
        component: () => import("@/views/ManageCalendarPhD"),
      },
      {
        path: "/ManageCalendar",
        name: "ManageCalendar",
        component: () => import("@/views/ManageCalendar"),
      },
      {
        path: "/ManageIdentity",
        name: "ManageIdentity",
        component: () => import("@/views/ManageIdentity"),
      },
      {
        path: "/ManageAward",
        name: "ManageAward",
        component: () => import("@/views/ManageAward"),
      },
      {
        path: "/ManageInformation",
        name: "ManageInformation",
        component: () => import("@/views/ManageInformation"),
      },
      {
        path: "/ManageCourseMaster",
        name: "ManageCourseMaster",
        component: () => import("@/views/ManageCourseMaster"),
      },
      {
        path: "/studentAmount",
        name: "studentAmount",
        component: () => import("@/views/studentAmount"),
      },
      {
        path: "/ManageFileDownload",
        name: "ManageFileDownload",
        component: () => import("@/views/ManageFileDownload"),
      },
      {
        path: "/CreateFileDownload",
        name: "CreateFileDownload",
        component: () =>
          import("@/components/ManageFileDownload/CreateFileDownload.vue"),
      },
      {
        path: "/EditFileDownload",
        name: "EditFileDownload",
        component: () =>
          import("@/components/ManageFileDownload/EditFileDownload.vue"),
      },
      {
        path: "/ViewFileDownload",
        name: "ViewFileDownload",
        component: () =>
          import("@/components/ManageFileDownload/ViewFileDownload.vue"),
      },
      {
        path: "/ManageRegisterPhD",
        name: "ManageRegisterPhD",
        component: () => import("@/views/ManageRegisterPhD"),
      },
      {
        path: "/CreateRegisterPhD",
        name: "CreateRegisterPhD",
        component: () =>
          import("@/components/ManageRegisterPhD/CreateRegisterPhD.vue"),
      },
      {
        path: "/EditRegisterPhD",
        name: "EditRegisterPhD",
        component: () =>
          import("@/components/ManageRegisterPhD/EditRegisterPhD.vue"),
      },
      {
        path: "/ViewRegisterPhD",
        name: "ViewRegisterPhD",
        component: () =>
          import("@/components/ManageRegisterPhD/ViewRegisterPhD.vue"),
      },
      {
        path: "/ManageRegisterMaster",
        name: "ManageRegisterMaster",
        component: () => import("@/views/ManageRegisterMaster"),
      },
      {
        path: "/CreateRegisterMaster",
        name: "CreateRegisterMaster",
        component: () =>
          import("@/components/ManageRegisterMaster/CreateRegisterMaster.vue"),
      },
      {
        path: "/EditRegisterMaster",
        name: "EditRegisterMaster",
        component: () =>
          import("@/components/ManageRegisterMaster/EditRegisterMaster.vue"),
      },
      {
        path: "/ViewRegisterMaster",
        name: "ViewRegisterMaster",
        component: () =>
          import("@/components/ManageRegisterMaster/ViewRegisterMaster.vue"),
      },
      {
        path: "/ManageScholarshipsMaster1",
        name: "ManageScholarshipsMaster1",
        component: () => import("@/views/ManageScholarshipsMaster1"),
      },
      {
        path: "/CreateScholarshipsMaster1",
        name: "CreateScholarshipsMaster1",
        component: () =>
          import(
            "@/components/ManageScholarshipsMaster1/CreateScholarshipsMaster1.vue"
          ),
      },
      {
        path: "/EditScholarshipsMaster1",
        name: "EditScholarshipsMaster1",
        component: () =>
          import(
            "@/components/ManageScholarshipsMaster1/EditScholarshipsMaster1.vue"
          ),
      },
      {
        path: "/ViewScholarshipsMaster1",
        name: "ViewScholarshipsMaster1",
        component: () =>
          import(
            "@/components/ManageScholarshipsMaster1/ViewScholarshipsMaster1.vue"
          ),
      },
      {
        path: "/ManageScholarshipsMaster",
        name: "ManageScholarshipsMaster",
        component: () => import("@/views/ManageScholarshipsMaster"),
      },
      {
        path: "/CreateScholarshipsMaster",
        name: "CreateScholarshipsMaster",
        component: () =>
          import(
            "@/components/ManageScholarshipsMaster/CreateScholarshipsMaster.vue"
          ),
      },
      {
        path: "/EditScholarshipsMaster",
        name: "EditScholarshipsMaster",
        component: () =>
          import(
            "@/components/ManageScholarshipsMaster/EditScholarshipsMaster.vue"
          ),
      },
      {
        path: "/ViewScholarshipsMaster",
        name: "ViewScholarshipsMaster",
        component: () =>
          import(
            "@/components/ManageScholarshipsMaster/ViewScholarshipsMaster.vue"
          ),
      },
      {
        path: "/ManageScholarshipsPhD1",
        name: "ManageScholarshipsPhD1",
        component: () => import("@/views/ManageScholarshipsPhD1"),
      },
      {
        path: "/CreateScholarshipsPhD1",
        name: "CreateScholarshipsPhD1",
        component: () =>
          import(
            "@/components/ManageScholarshipsPhD1/CreateScholarshipsPhD1.vue"
          ),
      },
      {
        path: "/EditScholarshipsPhD1",
        name: "EditScholarshipsPhD1",
        component: () =>
          import(
            "@/components/ManageScholarshipsPhD1/EditScholarshipsPhD1.vue"
          ),
      },
      {
        path: "/ViewScholarshipsPhD1",
        name: "ViewScholarshipsPhD1",
        component: () =>
          import(
            "@/components/ManageScholarshipsPhD1/ViewScholarshipsPhD1.vue"
          ),
      },
      {
        path: "/ManageScholarshipsPhD",
        name: "ManageScholarshipsPhD",
        component: () => import("@/views/ManageScholarshipsPhD"),
      },
      {
        path: "/CreateScholarshipsPhD",
        name: "CreateScholarshipsPhD",
        component: () =>
          import(
            "@/components/ManageScholarshipsPhD/CreateScholarshipsPhD.vue"
          ),
      },
      {
        path: "/EditScholarshipsPhD",
        name: "EditScholarshipsPhD",
        component: () =>
          import("@/components/ManageScholarshipsPhD/EditScholarshipsPhD.vue"),
      },
      {
        path: "/ViewScholarshipsPhD",
        name: "ViewScholarshipsPhD",
        component: () =>
          import("@/components/ManageScholarshipsPhD/ViewScholarshipsPhD.vue"),
      },
      {
        path: "/ManagePicture",
        name: "ManagePicture",
        component: () => import("@/views/ManagePicture"),
      },
      {
        path: "/CreatePicture",
        name: "CreatePicture",
        component: () => import("@/components/ManagePicture/CreatePicture.vue"),
      },
      {
        path: "/EditPicture",
        name: "EditPicture",
        component: () => import("@/components/ManagePicture/EditPicture.vue"),
      },
      {
        path: "/ViewPicture",
        name: "ViewPicture",
        component: () => import("@/components/ManagePicture/ViewPicture.vue"),
      },
      {
        path: "/ManageReview",
        name: "ManageReview",
        component: () => import("@/views/ManageReview"),
      },
      {
        path: "/CreateReview",
        name: "CreateReview",
        component: () => import("@/components/ManageReview/CreateReview.vue"),
      },
      {
        path: "/EditReview",
        name: "EditReview",
        component: () => import("@/components/ManageReview/EditReview.vue"),
      },
      {
        path: "/ViewReview",
        name: "ViewReview",
        component: () => import("@/components/ManageReview/ViewReview.vue"),
      },
      {
        path: "/ManageWelcomePopUp",
        name: "ManageWelcomePopUp",
        component: () => import("@/views/ManageWelcomePopUp"),
      },
      {
        path: "/CreateWelcomePopUp",
        name: "CreateWelcomePopUp",
        component: () =>
          import("@/components/ManageWelcomePopUp/CreateWelcomePopUp.vue"),
      },
      {
        path: "/EditWelcomePopUp",
        name: "EditWelcomePopUp",
        component: () =>
          import("@/components/ManageWelcomePopUp/EditWelcomePopUp.vue"),
      },
      {
        path: "/ViewWelcomePopUp",
        name: "ViewWelcomePopUp",
        component: () =>
          import("@/components/ManageWelcomePopUp/ViewWelcomePopUp.vue"),
      },
      {
        path: "/ManageOfficerDetails",
        name: "ManageOfficerDetails",
        component: () => import("@/views/ManageOfficerDetails"),
      },
      {
        path: "/CreateOfficerDetails",
        name: "CreateOfficerDetails",
        component: () =>
          import("@/components/ManageOfficerDetails/CreateOfficerDetails.vue"),
      },
      {
        path: "/EditOfficerDetails",
        name: "EditOfficerDetails",
        component: () =>
          import("@/components/ManageOfficerDetails/EditOfficerDetails.vue"),
      },
      {
        path: "/ViewOfficerDetails",
        name: "ViewOfficerDetails",
        component: () =>
          import("@/components/ManageOfficerDetails/ViewOfficerDetails.vue"),
      },
      {
        path: "/ManageTeacherDetails",
        name: "ManageTeacherDetails",
        component: () => import("@/views/ManageTeacherDetails"),
      },
      {
        path: "/CreateTeacherDetails",
        name: "CreateTeacherDetails",
        component: () =>
          import("@/components/ManageTeacherDetails/CreateTeacherDetails.vue"),
      },
      {
        path: "/EditTeacherDetails",
        name: "EditTeacherDetails",
        component: () =>
          import("@/components/ManageTeacherDetails/EditTeacherDetails.vue"),
      },
      {
        path: "/ViewTeacherDetails",
        name: "ViewTeacherDetails",
        component: () =>
          import("@/components/ManageTeacherDetails/ViewTeacherDetails.vue"),
      },
      {
        path: "/ManageStudentWorks",
        name: "ManageStudentWorks",
        component: () => import("@/views/ManageStudentWorks"),
      },
      {
        path: "/CreateStudentWorks",
        name: "CreateStudentWorks",
        component: () =>
          import("@/components/ManageStudentWorks/CreateStudentWorks.vue"),
      },
      {
        path: "/EditStudentWorks",
        name: "EditStudentWorks",
        component: () =>
          import("@/components/ManageStudentWorks/EditStudentWorks.vue"),
      },
      {
        path: "/ViewStudentWorks",
        name: "ViewStudentWorks",
        component: () =>
          import("@/components/ManageStudentWorks/ViewStudentWorks.vue"),
      },
      {
        path: "/ManageQualificationExams",
        name: "ManageQualificationExams",
        component: () => import("@/views/ManageQualificationExams"),
      },
      {
        path: "/CreateQualificationExams",
        name: "CreateQualificationExams",
        component: () =>
          import(
            "@/components/ManageQualificationExams/CreateQualificationExams.vue"
          ),
      },
      {
        path: "/EditQualificationExams",
        name: "EditQualificationExams",
        component: () =>
          import(
            "@/components/ManageQualificationExams/EditQualificationExams.vue"
          ),
      },
      {
        path: "/ViewQualificationExams",
        name: "ViewQualificationExams",
        component: () =>
          import(
            "@/components/ManageQualificationExams/ViewQualificationExams.vue"
          ),
      },
      {
        path: "/ManageKnowledgeTests",
        name: "ManageKnowledgeTests",
        component: () => import("@/views/ManageKnowledgeTests"),
      },
      {
        path: "/CreateKnowledgeTests",
        name: "CreateKnowledgeTests",
        component: () =>
          import("@/components/ManageKnowledgeTests/CreateKnowledgeTests.vue"),
      },
      {
        path: "/EditKnowledgeTests",
        name: "EditKnowledgeTests",
        component: () =>
          import("@/components/ManageKnowledgeTests/EditKnowledgeTests.vue"),
      },
      {
        path: "/ViewKnowledgeTests",
        name: "ViewKnowledgeTests",
        component: () =>
          import("@/components/ManageKnowledgeTests/ViewKnowledgeTests.vue"),
      },
      {
        path: "/ManageKnowledgeLibraries",
        name: "ManageKnowledgeLibraries",
        component: () => import("@/views/ManageKnowledgeLibraries"),
      },
      {
        path: "/CreateKnowledgeLibraries",
        name: "CreateKnowledgeLibraries",
        component: () =>
          import(
            "@/components/ManageKnowledgeLibraries/CreateKnowledgeLibraries.vue"
          ),
      },
      {
        path: "/EditKnowledgeLibraries",
        name: "EditKnowledgeLibraries",
        component: () =>
          import(
            "@/components/ManageKnowledgeLibraries/EditKnowledgeLibraries.vue"
          ),
      },
      {
        path: "/ViewKnowledgeLibraries",
        name: "ViewKnowledgeLibraries",
        component: () =>
          import(
            "@/components/ManageKnowledgeLibraries/ViewKnowledgeLibraries.vue"
          ),
      },
      {
        path: "/ManageAnnualReports",
        name: "ManageAnnualReports",
        component: () => import("@/views/ManageAnnualReports"),
      },
      {
        path: "/CreateAnnualReports",
        name: "CreateAnnualReports",
        component: () =>
          import("@/components/ManageAnnualReports/CreateAnnualReports.vue"),
      },
      {
        path: "/EditAnnualReports",
        name: "EditAnnualReports",
        component: () =>
          import("@/components/ManageAnnualReports/EditAnnualReports.vue"),
      },
      {
        path: "/ViewAnnualReports",
        name: "ViewAnnualReports",
        component: () =>
          import("@/components/ManageAnnualReports/ViewAnnualReports.vue"),
      },
      {
        path: "/ManageAcademicConferences3",
        name: "ManageAcademicConferences3",
        component: () => import("@/views/ManageAcademicConferences3"),
      },
      {
        path: "/ManageAcademicConferences4",
        name: "ManageAcademicConferences4",
        component: () => import("@/views/ManageAcademicConferences4"),
      },
      {
        path: "/CreateAcademicConferences2",
        name: "CreateAcademicConferences2",
        component: () =>
          import(
            "@/components/ManageAcademicConferences/CreateAcademicConferences2.vue"
          ),
      },
      {
        path: "/EditAcademicConferences2",
        name: "EditAcademicConferences2",
        component: () =>
          import(
            "@/components/ManageAcademicConferences/EditAcademicConferences2.vue"
          ),
      },
      {
        path: "/ViewAcademicConferences2",
        name: "ViewAcademicConferences2",
        component: () =>
          import(
            "@/components/ManageAcademicConferences/ViewAcademicConferences2.vue"
          ),
      },
      {
        path: "/ManageAcademicConferences1",
        name: "ManageAcademicConferences1",
        component: () => import("@/views/ManageAcademicConferences1"),
      },
      {
        path: "/CreateAcademicConferences1",
        name: "CreateAcademicConferences1",
        component: () =>
          import(
            "@/components/ManageAcademicConferences/CreateAcademicConferences1.vue"
          ),
      },
      {
        path: "/EditAcademicConferences1",
        name: "EditAcademicConferences1",
        component: () =>
          import(
            "@/components/ManageAcademicConferences/EditAcademicConferences1.vue"
          ),
      },
      {
        path: "/ViewAcademicConferences1",
        name: "ViewAcademicConferences1",
        component: () =>
          import(
            "@/components/ManageAcademicConferences/ViewAcademicConferences1.vue"
          ),
      },
      {
        path: "/ManageAcademicConferences2",
        name: "ManageAcademicConferences2",
        component: () => import("@/views/ManageAcademicConferences2"),
      },
      {
        path: "/ManageMOU",
        name: "ManageMOU",
        component: () => import("@/views/ManageMOU"),
      },
      {
        path: "/CreateMOU",
        name: "CreateMOU",
        component: () => import("@/components/ManageMOU/CreateMOU.vue"),
      },
      {
        path: "/EditMOU",
        name: "EditMOU",
        component: () => import("@/components/ManageMOU/EditMOU.vue"),
      },
      {
        path: "/ViewMOU",
        name: "ViewMOU",
        component: () => import("@/components/ManageMOU/ViewMOU.vue"),
      },
      {
        path: "/ManageAcademicJournal",
        name: "ManageAcademicJournal",
        component: () => import("@/views/ManageAcademicJournal"),
      },
      {
        path: "/CreateAcademicJournal",
        name: "CreateAcademicJournal",
        component: () =>
          import(
            "@/components/ManageAcademicJournal/CreateAcademicJournal.vue"
          ),
      },
      {
        path: "/EditAcademicJournal",
        name: "EditAcademicJournal",
        component: () =>
          import("@/components/ManageAcademicJournal/EditAcademicJournal.vue"),
      },
      {
        path: "/ViewAcademicJournal",
        name: "ViewAcademicJournal",
        component: () =>
          import("@/components/ManageAcademicJournal/ViewAcademicJournal.vue"),
      },
      {
        path: "/ManageThesisAndResearch",
        name: "ManageThesisAndResearch",
        component: () => import("@/views/ManageThesisAndResearch"),
      },
      {
        path: "/CreateThesisAndResearch",
        name: "CreateThesisAndResearch",
        component: () =>
          import(
            "@/components/ManageThesisAndResearch/CreateThesisAndResearch.vue"
          ),
      },
      {
        path: "/EditThesisAndResearch",
        name: "EditThesisAndResearch",
        component: () =>
          import(
            "@/components/ManageThesisAndResearch/EditThesisAndResearch.vue"
          ),
      },
      {
        path: "/ViewThesisAndResearch",
        name: "ViewThesisAndResearch",
        component: () =>
          import(
            "@/components/ManageThesisAndResearch/ViewThesisAndResearch.vue"
          ),
      },
      {
        path: "/ManageMajor",
        name: "ManageMajor",
        component: () => import("@/views/ManageMajor"),
      },
      {
        path: "/CreateMajor",
        name: "CreateMajor",
        component: () => import("@/components/ManageMajor/CreateMajor.vue"),
      },
      {
        path: "/EditMajor",
        name: "EditMajor",
        component: () => import("@/components/ManageMajor/EditMajor.vue"),
      },
      {
        path: "/ViewMajor",
        name: "ViewMajor",
        component: () => import("@/components/ManageMajor/ViewMajor.vue"),
      },
      {
        path: "/ManageMajorSub",
        name: "ManageMajorSub",
        component: () => import("@/views/ManageMajorSub"),
      },
      {
        path: "/CreateMajorSub",
        name: "CreateMajorSub",
        component: () =>
          import("@/components/ManageMajorSub/CreateMajorSub.vue"),
      },
      {
        path: "/EditMajorSub",
        name: "EditMajorSub",
        component: () => import("@/components/ManageMajorSub/EditMajorSub.vue"),
      },
      {
        path: "/ViewMajorSub",
        name: "ViewMajorSub",
        component: () => import("@/components/ManageMajorSub/ViewMajorSub.vue"),
      },
      {
        path: "/ManageSuccessfulAlumni",
        name: "ManageSuccessfulAlumni",
        component: () => import("@/views/ManageSuccessfulAlumni"),
      },
      {
        path: "/CreateSuccessfulAlumni",
        name: "CreateSuccessfulAlumni",
        component: () =>
          import(
            "@/components/ManageSuccessfulAlumni/CreateSuccessfulAlumni.vue"
          ),
      },
      {
        path: "/EditSuccessfulAlumni",
        name: "EditSuccessfulAlumni",
        component: () =>
          import(
            "@/components/ManageSuccessfulAlumni/EditSuccessfulAlumni.vue"
          ),
      },
      {
        path: "/ViewSuccessfulAlumni",
        name: "ViewSuccessfulAlumni",
        component: () =>
          import(
            "@/components/ManageSuccessfulAlumni/ViewSuccessfulAlumni.vue"
          ),
      },
      {
        path: "/ManageAlumniArticles",
        name: "ManageAlumniArticles",
        component: () => import("@/views/ManageAlumniArticles"),
      },
      {
        path: "/CreateAlumniArticles",
        name: "CreateAlumniArticles",
        component: () =>
          import("@/components/ManageAlumniArticles/CreateAlumniArticles.vue"),
      },
      {
        path: "/EditAlumniArticles",
        name: "EditAlumniArticles",
        component: () =>
          import("@/components/ManageAlumniArticles/EditAlumniArticles.vue"),
      },
      {
        path: "/ViewAlumniArticles",
        name: "ViewAlumniArticles",
        component: () =>
          import("@/components/ManageAlumniArticles/ViewAlumniArticles.vue"),
      },
      {
        path: "/ManageCourse",
        name: "ManageCourse",
        component: () => import("@/views/ManageCourse"),
      },
      {
        path: "/CreateCourse",
        name: "CreateCourse",
        component: () => import("@/components/Course/CreateCourse.vue"),
      },
      {
        path: "/EditCourse",
        name: "EditCourse",
        component: () => import("@/components/Course/EditCourse.vue"),
      },
      {
        path: "/ViewCourse",
        name: "ViewCourse",
        component: () => import("@/components/Course/ViewCourse.vue"),
      },
      {
        path: "/ManageCoursePhD",
        name: "ManageCoursePhD",
        component: () => import("@/views/ManageCoursePhD"),
      },
      {
        path: "/CreateCoursePhD",
        name: "CreateCoursePhD",
        component: () => import("@/components/CoursePhD/CreateCoursePhD.vue"),
      },
      {
        path: "/EditCoursePhD",
        name: "EditCoursePhD",
        component: () => import("@/components/CoursePhD/EditCoursePhD.vue"),
      },
      {
        path: "/ViewCoursePhD",
        name: "ViewCoursePhD",
        component: () => import("@/components/CoursePhD/ViewCoursePhD.vue"),
      },
      // {
      //   path: "/CreateCourse1",
      //   name: "CreateCourse1",
      //   component: () => import("@/components/Course/CreateCourse1.vue"),
      // },
      // {
      //   path: "/EditCourse1",
      //   name: "EditCourse1",
      //   component: () => import("@/components/Course/EditCourse1.vue"),
      // },
      // {
      //   path: "/ViewCourse1",
      //   name: "ViewCourse1",
      //   component: () => import("@/components/Course/ViewCourse1.vue"),
      // },
      {
        path: "/ManageLevelCourse",
        name: "ManageLevelCourse",
        component: () => import("@/views/ManageLevelCourse"),
      },
      {
        path: "/CreateLevelCourse",
        name: "CreateLevelCourse",
        component: () =>
          import("@/components/LevelCourse/CreateLevelCourse.vue"),
      },
      {
        path: "/EditLevelCourse",
        name: "EditLevelCourse",
        component: () => import("@/components/LevelCourse/EditLevelCourse.vue"),
      },
      {
        path: "/ViewLevelCourse",
        name: "ViewLevelCourse",
        component: () => import("@/components/LevelCourse/ViewLevelCourse.vue"),
      },
      {
        path: "/ManageFaculty",
        name: "ManageFaculty",
        component: () => import("@/views/ManageFaculty"),
      },
      {
        path: "/CreateFaculty",
        name: "CreateFaculty",
        component: () => import("@/components/Faculty/CreateFaculty.vue"),
      },
      {
        path: "/EditFaculty",
        name: "EditFaculty",
        component: () => import("@/components/Faculty/EditFaculty.vue"),
      },
      {
        path: "/ViewFaculty",
        name: "ViewFaculty",
        component: () => import("@/components/Faculty/ViewFaculty.vue"),
      },
      {
        path: "/ManageNews",
        name: "ManageNews",
        component: () => import("@/views/ManageNews"),
      },
      {
        path: "/CreateNews",
        name: "CreateNews",
        component: () => import("@/components/News/CreateNews.vue"),
      },
      {
        path: "/EditNews",
        name: "EditNews",
        component: () => import("@/components/News/EditNews.vue"),
      },
      {
        path: "/ViewNews",
        name: "ViewNews",
        component: () => import("@/components/News/ViewNews.vue"),
      },
      {
        path: "/ManageNews1",
        name: "ManageNews1",
        component: () => import("@/views/ManageNews1"),
      },
      {
        path: "/CreateNews1",
        name: "CreateNews1",
        component: () => import("@/components/News1/CreateNews1.vue"),
      },
      {
        path: "/EditNews1",
        name: "EditNews1",
        component: () => import("@/components/News1/EditNews1.vue"),
      },
      {
        path: "/ViewNews1",
        name: "ViewNews1",
        component: () => import("@/components/News1/ViewNews1.vue"),
      },
      {
        path: "/ManageNews2",
        name: "ManageNews2",
        component: () => import("@/views/ManageNews2"),
      },
      {
        path: "/CreateNews2",
        name: "CreateNews2",
        component: () => import("@/components/News2/CreateNews2.vue"),
      },
      {
        path: "/EditNews2",
        name: "EditNews2",
        component: () => import("@/components/News2/EditNews2.vue"),
      },
      {
        path: "/ViewNews2",
        name: "ViewNews2",
        component: () => import("@/components/News2/ViewNews2.vue"),
      },
      {
        path: "/ManageNews3",
        name: "ManageNews3",
        component: () => import("@/views/ManageNews3"),
      },
      {
        path: "/CreateNews3",
        name: "CreateNews3",
        component: () => import("@/components/News3/CreateNews3.vue"),
      },
      {
        path: "/EditNews3",
        name: "EditNews3",
        component: () => import("@/components/News3/EditNews3.vue"),
      },
      {
        path: "/ViewNews3",
        name: "ViewNews3",
        component: () => import("@/components/News3/ViewNews3.vue"),
      },
      {
        path: "/ManageStudentComment",
        name: "ManageStudentComment",
        component: () => import("@/views/ManageStudentComment"),
      },
      {
        path: "/CreateStudentComment",
        name: "CreateStudentComment",
        component: () =>
          import("@/components/StudentComment/CreateStudentComment.vue"),
      },
      {
        path: "/ViewStudentComment",
        name: "ViewStudentComment",
        component: () =>
          import("@/components/StudentComment/ViewStudentComment.vue"),
      },
      {
        path: "/EditStudentComment",
        name: "EditStudentComment",
        component: () =>
          import("@/components/StudentComment/EditStudentComment.vue"),
      },
      {
        path: "/ManageUser",
        name: "ManageUser",
        component: () => import("@/views/ManageUser"),
      },
      {
        path: "/ViewUser",
        name: "ViewUser",
        component: () => import("@/components/ManageUser/ViewUser.vue"),
      },
      {
        path: "/EditUser",
        name: "EditUser",
        component: () => import("@/components/ManageUser/EditUser.vue"),
      },

      // {
      //     path: '/ManageCategory',
      //     name: 'ManageCategory',
      //     component: () =>
      //         import('@/views/ManageCategory')
      // },
      // {
      //     path: '/CreateCategory',
      //     name: 'CreateCategory',
      //     component: () =>
      //         import('@/components/Category/CreateCategory.vue')
      // },
      // {
      //     path: '/EditCategory',
      //     name: 'EditCategory',
      //     component: () =>
      //         import('@/components/Category/EditCategory.vue')
      // },
      // {
      //     path: '/ViewCategory',
      //     name: 'ViewCategory',
      //     component: () =>
      //         import('@/components/Category/ViewCategory.vue')
      // },
      // {
      //     path: '/ManageType',
      //     name: 'ManageType',
      //     component: () =>
      //         import('@/views/ManageType')
      // },
      // {
      //     path: '/CreateType',
      //     name: 'CreateType',
      //     component: () =>
      //         import('@/components/Type/CreateType.vue')
      // },
      // {
      //     path: '/EditType',
      //     name: 'EditType',
      //     component: () =>
      //         import('@/components/Type/EditType.vue')
      // },
      // {
      //     path: '/ViewType',
      //     name: 'ViewType',
      //     component: () =>
      //         import('@/components/Type/ViewType.vue')
      // },
      // {
      //     path: '/ManageBrand',
      //     name: 'ManageBrand',
      //     component: () =>
      //         import('@/views/ManageBrand')
      // },
      // {
      //     path: '/CreateBrand',
      //     name: 'CreateBrand',
      //     component: () =>
      //         import('@/components/Brand/CreateBrand.vue')
      // },
      // {
      //     path: '/EditBrand',
      //     name: 'EditBrand',
      //     component: () =>
      //         import('@/components/Brand/EditBrand.vue')
      // },
      // {
      //     path: '/ViewBrand',
      //     name: 'ViewBrand',
      //     component: () =>
      //         import('@/components/Brand/ViewBrand.vue')
      // },

      {
        path: "/ManageChangePassword",
        name: "ManageChangePassword",
        component: () => import("@/views/ManageChangePassword"),
      },
      {
        path: "/ManageBanners",
        name: "ManageBanners",
        component: () => import("@/views/ManageBanners"),
      },
      {
        path: "/CreateBanners",
        name: "CreateBanners",
        component: () => import("@/components/Banners/CreateBanners.vue"),
      },
      {
        path: "/EditBanners",
        name: "EditBanners",
        component: () => import("@/components/Banners/EditBanners.vue"),
      },
      {
        path: "/ViewBanners",
        name: "ViewBanners",
        component: () => import("@/components/Banners/ViewBanners.vue"),
      },
      {
        path: "/ManageBanners_1",
        name: "ManageBanners_1",
        component: () => import("@/views/ManageBanners_1"),
      },
      {
        path: "/CreateBanners_1",
        name: "CreateBanners_1",
        component: () => import("@/components/Banners_1/CreateBanners_1.vue"),
      },
      {
        path: "/EditBanners_1",
        name: "EditBanners_1",
        component: () => import("@/components/Banners_1/EditBanners_1.vue"),
      },
      {
        path: "/ViewBanners_1",
        name: "ViewBanners_1",
        component: () => import("@/components/Banners_1/ViewBanners_1.vue"),
      },
      {
        path: "/ManageBanners_2",
        name: "ManageBanners_2",
        component: () => import("@/views/ManageBanners_2"),
      },
      {
        path: "/CreateBanners_2",
        name: "CreateBanners_2",
        component: () => import("@/components/Banners_2/CreateBanners_2.vue"),
      },
      {
        path: "/EditBanners_2",
        name: "EditBanners_2",
        component: () => import("@/components/Banners_2/EditBanners_2.vue"),
      },
      {
        path: "/ViewBanners_2",
        name: "ViewBanners_2",
        component: () => import("@/components/Banners_2/ViewBanners_2.vue"),
      },
      {
        path: "/ManageBanners_3",
        name: "ManageBanners_3",
        component: () => import("@/views/ManageBanners_3"),
      },
      {
        path: "/CreateBanners_3",
        name: "CreateBanners_3",
        component: () => import("@/components/Banners_3/CreateBanners_3.vue"),
      },
      {
        path: "/EditBanners_3",
        name: "EditBanners_3",
        component: () => import("@/components/Banners_3/EditBanners_3.vue"),
      },
      {
        path: "/ViewBanners_3",
        name: "ViewBanners_3",
        component: () => import("@/components/Banners_3/ViewBanners_3.vue"),
      },
      {
        path: "/ManageBanners_4",
        name: "ManageBanners_4",
        component: () => import("@/views/ManageBanners_4"),
      },
      {
        path: "/CreateBanners_4",
        name: "CreateBanners_4",
        component: () => import("@/components/Banners_4/CreateBanners_4.vue"),
      },
      {
        path: "/EditBanners_4",
        name: "EditBanners_4",
        component: () => import("@/components/Banners_4/EditBanners_4.vue"),
      },
      {
        path: "/ViewBanners_4",
        name: "ViewBanners_4",
        component: () => import("@/components/Banners_4/ViewBanners_4.vue"),
      },
      {
        path: "/ManageBanners_5",
        name: "ManageBanners_5",
        component: () => import("@/views/ManageBanners_5"),
      },
      {
        path: "/CreateBanners_5",
        name: "CreateBanners_5",
        component: () => import("@/components/Banners_5/CreateBanners_5.vue"),
      },
      {
        path: "/EditBanners_5",
        name: "EditBanners_5",
        component: () => import("@/components/Banners_5/EditBanners_5.vue"),
      },
      {
        path: "/ViewBanners_5",
        name: "ViewBanners_5",
        component: () => import("@/components/Banners_5/ViewBanners_5.vue"),
      },
      {
        path: "/ManageBanners_8",
        name: "ManageBanners_8",
        component: () => import("@/views/ManageBanners_8"),
      },
      {
        path: "/CreateBanners_8",
        name: "CreateBanners_8",
        component: () => import("@/components/Banners_8/CreateBanners_8.vue"),
      },
      {
        path: "/EditBanners_8",
        name: "EditBanners_8",
        component: () => import("@/components/Banners_8/EditBanners_8.vue"),
      },
      {
        path: "/ViewBanners_8",
        name: "ViewBanners_8",
        component: () => import("@/components/Banners_8/ViewBanners_8.vue"),
      },

      {
        path: "/ManageBanners_9",
        name: "ManageBanners_9",
        component: () => import("@/views/ManageBanners_9"),
      },
      {
        path: "/CreateBanners_9",
        name: "CreateBanners_9",
        component: () => import("@/components/Banners_9/CreateBanners_9.vue"),
      },
      {
        path: "/EditBanners_9",
        name: "EditBanners_9",
        component: () => import("@/components/Banners_9/EditBanners_9.vue"),
      },
      {
        path: "/ViewBanners_9",
        name: "ViewBanners_9",
        component: () => import("@/components/Banners_9/ViewBanners_9.vue"),
      },

      {
        path: "/ManageBanners_10",
        name: "ManageBanners_10",
        component: () => import("@/views/ManageBanners_10"),
      },
      {
        path: "/CreateBanners_10",
        name: "CreateBanners_10",
        component: () => import("@/components/Banners_10/CreateBanners_10.vue"),
      },
      {
        path: "/EditBanners_10",
        name: "EditBanners_10",
        component: () => import("@/components/Banners_10/EditBanners_10.vue"),
      },
      {
        path: "/ViewBanners_10",
        name: "ViewBanners_10",
        component: () => import("@/components/Banners_10/ViewBanners_10.vue"),
      },
      {
        path: "/ManagePartners",
        name: "ManagePartners",
        component: () => import("@/views/ManagePartners"),
      },
      {
        path: "/CreatePartners",
        name: "CreatePartners",
        component: () => import("@/components/Partners/CreatePartners.vue"),
      },
      {
        path: "/EditPartners",
        name: "EditPartners",
        component: () => import("@/components/Partners/EditPartners.vue"),
      },
      {
        path: "/ViewPartners",
        name: "ViewPartners",
        component: () => import("@/components/Partners/ViewPartners.vue"),
      },
      {
        path: "/formlist",
        name: "formlist",
        component: () => import("@/views/FormList.vue"),
      },
      {
        path: "/reportlist",
        name: "reportlist",
        component: () => import("@/views/ReportList.vue"),
      },
      {
        path: "/Table1",
        name: "Table1",
        component: () => import("@/views/Table1.vue"),
      },
      {
        path: "/Table2",
        name: "Table2",
        component: () => import("@/views/Table2.vue"),
      },
      {
        path: "/Table3",
        name: "Table3",
        component: () => import("@/views/Table3.vue"),
      },
      {
        path: "/Table4",
        name: "Table4",
        component: () => import("@/views/Table4.vue"),
      },
      {
        path: "/c_configs",
        name: "c_configs",
        component: () => import("@/views/c_configs.vue"),
      },

      // {
      //     path: '/CreateProduct',
      //     name: 'CreateProduct',
      //     component: () =>
      //         import('@/components/Product/CreateProduct.vue')
      // },
      // {
      //     path: '/ManageProduct',
      //     name: 'ManageProduct',
      //     component: () =>
      //         import('@/views/ManageProduct')
      // },
      // {
      //     path: '/CreateProduct',
      //     name: 'CreateProduct',
      //     component: () =>
      //         import('@/components/Product/CreateProduct.vue')
      // },
      // {
      //     path: '/viewProduct',
      //     name: 'viewProduct',
      //     component: () =>
      //         import('@/components/Product/viewProduct.vue')
      // },
      // {
      //     path: '/EditProduct',
      //     name: 'EditProduct',
      //     component: () =>
      //         import('@/components/Product/EditProduct.vue')
      // },
      {
        path: "/CreateUser",
        name: "CreateUser",
        component: () => import("@/components/User/CreateUser"),
      },
      {
        path: "/viewUser",
        name: "viewUser",
        component: () => import("@/components/User/viewUser.vue"),
      },
      {
        path: "/UpdateUser",
        name: "UpdateUser",
        component: () => import("@/components/User/UpdateUser.vue"),
      },
      // {
      //     path: '/ManageOrder',
      //     name: 'ManageOrder',
      //     component: () =>
      //         import('@/views/ManageOrder')
      // },
      // {
      //     path: '/viewOrder',
      //     name: 'viewOrder',
      //     component: () =>
      //         import('@/components/Order/viewOrder.vue')
      // },
      // {
      //     path: '/EditOrder',
      //     name: 'EditOrder',
      //     component: () =>
      //         import('@/components/Order/EditOrder.vue')
      // },
      // {
      //     path: '/ManageCompany',
      //     name: 'ManageCompany',
      //     component: () =>
      //         import('@/views/ManageCompany')
      // },
      // {
      //     path: '/CreateCompany',
      //     name: 'CreateCompany',
      //     component: () =>
      //         import('@/components/Company/CreateCompany.vue')
      // },
      // {
      //     path: '/EditCompany',
      //     name: 'EditCompany',
      //     component: () =>
      //         import('@/components/Company/EditCompany.vue')
      // },
      // {
      //     path: '/ViewCompany',
      //     name: 'ViewCompany',
      //     component: () =>
      //         import('@/components/Company/ViewCompany.vue')
      // },
      // {
      //     path: '/Dashboard',
      //     name: 'Dashboard',
      //     component: () =>
      //         import('@/views/Dashboard')
      // },

      // {
      //     path: '/ManageCoupon',
      //     name: 'ManageCoupon',
      //     component: () =>
      //         import('@/views/ManageCoupon')
      // },
      // {
      //     path: '/CreateCoupon',
      //     name: 'CreateCoupon',
      //     component: () =>
      //         import('@/components/Coupon/CreateCoupon.vue')
      // },
      // {
      //     path: '/ViewCoupon',
      //     name: 'ViewCoupon',
      //     component: () =>
      //         import('@/components/Coupon/ViewCoupon.vue')
      // },
      // {
      //     path: '/EditCoupon',
      //     name: 'EditCoupon',
      //     component: () =>
      //         import('@/components/Coupon/EditCoupon.vue')
      // },
      // {
      //     path: '/ManageVoucher',
      //     name: 'ManageVoucher',
      //     component: () =>
      //         import('@/views/ManageVoucher')
      // },
      // {
      //     path: '/CreateVoucher',
      //     name: 'CreateVoucher',
      //     component: () =>
      //         import('@/components/Voucher/CreateVoucher.vue')
      // },
      // {
      //     path: '/ViewVoucher',
      //     name: 'ViewVoucher',
      //     component: () =>
      //         import('@/components/Voucher/ViewVoucher.vue')
      // },
      // {
      //     path: '/EditVoucher',
      //     name: 'EditVoucher',
      //     component: () =>
      //         import('@/components/Voucher/EditVoucher.vue')
      // },
      // {
      //     path: '/ManageAnnouncement',
      //     name: 'ManageAnnouncement',
      //     component: () =>
      //         import('@/views/ManageAnnouncement')
      // },
      // {
      //     path: '/CreateAnnouncement',
      //     name: 'CreateAnnouncement',
      //     component: () =>
      //         import('@/components/Announcement/CreateAnnouncement.vue')
      // },
      // {
      //     path: '/ViewAnnouncement',
      //     name: 'ViewAnnouncement',
      //     component: () =>
      //         import('@/components/Announcement/ViewAnnouncement.vue')
      // },
      // {
      //     path: '/EditAnnouncement',
      //     name: 'EditAnnouncement',
      //     component: () =>
      //         import('@/components/Announcement/EditAnnouncement.vue')
      // },
      // {
      //     path: '/Report1',
      //     name: 'Report1',
      //     component: () =>
      //         import('@/components/Report/Report1.vue')
      // },
      // {
      //     path: '/Report2',
      //     name: 'Report2',
      //     component: () =>
      //         import('@/components/Report/Report2.vue')
      // },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
